import { defineStore } from "pinia";
import { computed, type Ref, ref } from "vue";
import { datadogRum } from "@datadog/browser-rum";
import { resolvePolicyRules } from "./rules/policyDisplayRules";
import axios, { type AxiosResponse, isAxiosError } from "axios";
import prodData from "./prod-test-data.json";

import type {
	ApplicationPolicy,
	CustomerInfoDTO,
	Tenant,
	User,
	CustomerConsentDTO,
	ApplicationPolicyDTO,
	UserEnrollment,
	SignInUserData,
	PreSaveUserData,
} from "@/models";

interface ForgotPasswordPayload {
	password: string;
	token: string;
}

interface PasswordResetPayload {
	dateOfBirth: string;
	email: string;
	familyName: string;
	givenName: string;
}

interface SignUpUserPayload {
	givenName: string;
	familyName: string;
	dateOfBirth: string;
	email: string;
	password: string;
}

export const useUserStore = defineStore(
	"user",
	() => {
		// Dependencies
		const config = useRuntimeConfig();
		const router = useRouter();

		// State
		const accountAlreadyExists: Ref<boolean> = ref(false);
		const accountCreationInProgress: Ref<boolean> = ref(false);
		const alertMessage: Ref<string> = ref("");
		const applications: Ref<ApplicationPolicy[]> = ref<ApplicationPolicy[]>(
			[] as ApplicationPolicy[],
		);
		const authToken: Ref<string> = ref("");
		const authValid: Ref<boolean> = ref(true);
		const consentModal: Ref<boolean> = ref(true);
		const showMissingInfoModal: Ref<boolean> = ref(false);
		const codeExpiry: Ref<number> = ref(0);
		const customerInfo: Ref<CustomerInfoDTO> = ref({} as CustomerInfoDTO);
		const defaultTenant: Ref<string> = ref("");
		const dupEmail: Ref<boolean> = ref(false);
		const emailVerificationSubmitError: Ref<boolean> = ref(false);
		const inboundPhone: Ref<string> = ref("8558285372");
		const isNameSubmitted: Ref<boolean> = ref(false);
		const isLoading: Ref<boolean> = ref(true);
		const isVerified: Ref<boolean> = ref(false);
		const policies: Ref<ApplicationPolicy[]> = ref<ApplicationPolicy[]>(
			[] as ApplicationPolicy[],
		);
		const registrationNotFound: Ref<boolean> = ref(false);
		const showMobileBanner: Ref<boolean> = ref(true);
		const showPolicyConcierge: Ref<boolean> = ref(false);
		const tenant: Ref<Tenant> = ref({} as Tenant);
		const user: Ref<User> = ref({} as User);
		const userConsent: Ref<CustomerConsentDTO> = ref({} as CustomerConsentDTO);
		const userObject: Ref<{
			userEmail: string;
			password: string;
			userId: string;
			userOtp: string;
		}> = ref({
			userEmail: "",
			password: "",
			userId: "",
			userOtp: "",
		});
		const userEnrollment: Ref<UserEnrollment> = ref({} as UserEnrollment);
		const showProgressBar = ref(false);
		const testAccounts: string[] = [
			"gene.genito@example.com",
			"genevieve.genito@example.com",
			"thesampsons@example.com",
			"sinclair.sinatra@example.com",
			"sindy.sinclair@example.com",
			"allison.allen@example.com",
		];

		// Getters

		const computedInboundPhone = computed(() => {
			if (tenant.value?.tenantKey === "unitedmedicareadvisors") {
				return "1-855-538-2998";
			} else {
				return "1-855-721-7206";
			}
		});

		const planStatus = computed(() => {
			let returnedStatus = "";
			if (customerInfo.value) {
				if (policies.value.length > 0) {
					let status: ApplicationPolicy = {} as ApplicationPolicy;
					policies.value.forEach((policy: ApplicationPolicy) => {
						if (policy.product !== "Dental, Vision, Health") {
							status = policy;
						}
					});
					returnedStatus = status?.status;
				}
			}
			return returnedStatus;
		});

		const postEffectiveDateDays = computed(() => {
			if (customerInfo.value) {
				const effectivePolicy = policies.value.map((pol: ApplicationPolicy) => {
					if (pol.product !== "Dental, Vision, Health") {
						return pol;
					}
				});
				const effectiveDate = effectivePolicy[0]
					? new Date(effectivePolicy[0].effectiveDate)
					: new Date();
				const currentDate = new Date();
				const Difference_In_Time =
					currentDate.getTime() - effectiveDate.getTime();
				const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
				return Difference_In_Days;
			} else {
				return 0;
			}
		});

		const isAnonymous = computed(() => {
			return !user.value;
		});

		const isAuthenticated = computed(() => {
			return !!authToken.value;
		});

		// functions

		// wrapper to manage showing the progress bar
		async function withProgressbar(asyncAction: () => Promise<unknown>) {
			showProgressBar.value = true;
			try {
				await asyncAction();
			} finally {
				showProgressBar.value = false;
			}
		}

		// This is to manually trigger the progress bar since there is no fetch call associated with a page to trigger it
		function displayProgressBar(display: boolean) {
			showProgressBar.value = display;
		}

		function activeApplicationNetworkLoad(payload: ApplicationPolicy[]) {
			datadogRum.addAction("Post rule evaluation  of active applications", {
				activeApplications: payload,
			});
		}

		async function assignPolicies(result: ApplicationPolicyDTO) {
			const resolvedPolicies = resolvePolicyRules(result);
			// set new values
			applications.value = resolvedPolicies.applications
				? resolvedPolicies.applications
				: [];
			policies.value = resolvedPolicies.policies
				? resolvedPolicies.policies
				: [];
		}

		function activePoliciesNetworkLoad(payload: ApplicationPolicy[]) {
			datadogRum.addAction("Post rule evaluation  of active policies", {
				activePolicies: payload,
			});
		}

		async function fetchPolicies() {
			await withProgressbar(async () => {
				const payload = {
					email: user.value.info?.email,
					firstName: user.value.info?.givenName,
					lastName: user.value.info?.familyName,
					dateOfBirth: user.value.info?.dateOfBirth,
				};
				try {
					// Clear existing applications and policies retrieved with session
					// so even if this call fails, the app won't use the old object with different names
					applications.value = [];
					policies.value = [];
					// Look up apps and policies in Salesforce
					if (
						testAccounts.includes(payload.email) &&
						config.public.ENVIRONMENT === "prod"
					) {
						await assignPolicies(prodData as ApplicationPolicyDTO);
					} else {
						const result = await axios.post<ApplicationPolicyDTO>(
							`${config.public.AIRLOCK_SERVICE_URL}/portal/records`,
							payload,
							{ headers: { Authorization: `Bearer ${authToken.value}` } },
						);
						datadogRum.addAction("Loading Applications and Policies", {
							applicationsAndPolicies: result.data,
						});
						await assignPolicies(result.data);
					}
				} catch (error) {
					if (isAxiosError(error)) {
						if (error?.response?.status === 404) {
							console.log(
								"fetchPolicies - No applications or policies found in Salesforce",
								error?.response,
							);
							datadogRum.addError(error, {
								message:
									"fetchPolicies - No applications or policies found in Salesforce",
							});
							return;
						}
					}
					datadogRum.addError(error, {
						message: "fetchPolicies - unknown error",
					});
					console.log("fetchPolicies - unknown error:", error);
				}
			});
		}

		async function fetchTenant(key: string) {
			try {
				const token = await axios.get<string>(
					`${config.public.TENANT_SERVICE_URL}/login/ez-auth-token/${key}`,
				);
				const response: AxiosResponse<Tenant> = await axios.get<Tenant>(
					`${config.public.TENANT_SERVICE_URL}/tenant`,
					{
						headers: { Authorization: `Bearer ${token.data}` },
					},
				);
				tenant.value = { ...response.data };
			} catch (error) {
				console.log("There was an error retrieving the tenant key", error);
				datadogRum.addError(error, {
					message: "There was an error retrieving the tenant key",
				});
			}
		}

		async function forgotPassword(data: ForgotPasswordPayload) {
			const payload = new URLSearchParams();
			payload.append("password", data.password);

			axios
				.post(
					`${config.public.TENANT_SERVICE_URL}/login/password/forgot/${data.token}?origin=benefit`,
					payload,
					{
						headers: {
							"Content-Type": "application/x-www-form-urlencoded",
							Authorization: `Bearer ${authToken.value}`,
						},
					},
				)
				.then(() => {
					// REDIRECT TO SUCCESS MESSAGE
					// eslint-disable-next-line no-undef
					router.push({
						path: "/password-reset-confirmed",
						query: { isSuccessful: "true" },
					});
				})
				.catch((error) => {
					console.error(
						"Error trying to send new password for resetting: ",
						error,
					);
					datadogRum.addError(error, {
						message: "Error trying to send new password for resetting",
					});
					// eslint-disable-next-line no-undef
					router.push({
						path: "/password-reset-confirmed",
						query: { isSuccessful: "false" },
					});
				});
		}

		async function getCustomerInfo() {
			try {
				const payload = {
					email: user.value.info?.email,
					givenName: user.value.info?.givenName,
					familyName: user.value.info?.familyName,
					dateOfBirth: user.value.info?.dateOfBirth,
					tenantId: tenant.value?.id,
				};
				await axios
					.post<CustomerInfoDTO>(
						`${config.public.AIRLOCK_SERVICE_URL}/portal/customer-information`,
						payload,
						{
							headers: { Authorization: `Bearer ${authToken.value}` },
						},
					)
					.then((result: AxiosResponse<CustomerInfoDTO>) => {
						customerInfo.value = result.data;
						const tokenCookie = useCookie("cbp-token", {
							path: "/",
							maxAge: 60 * 60 * 24 * 7,
						});
						tokenCookie.value = authToken.value;
						datadogRum.setUser({
							id: user.value?.id,
							name:
								user.value?.info.givenName + " " + user.value?.info.familyName,
							firstName: user.value?.info.givenName,
							lastName: user.value?.info.familyName,
							email: user.value?.info.email,
							tenantKey: tenant.value?.tenantKey,
							accountIds: customerInfo.value.accountIdsFound.toString(),
						});
					})
					.catch((error) => {
						console.error("Error fetching customer info: ", error);
						datadogRum.addError(error, {
							message: "Error fetching customer info",
						});
					});
			} catch (error) {
				console.log(error);
				datadogRum.addError(error, {
					message: "getCustomerInfo",
				});
			}
		}

		async function getEnrollmentInfo() {
			try {
				const payload = {
					email: user.value.info?.email,
					givenName: user.value.info?.givenName,
					familyName: user.value.info?.familyName,
					dateOfBirth: user.value.info?.dateOfBirth,
				};
				const recentEnrollment: AxiosResponse<UserEnrollment> =
					await axios.post<UserEnrollment>(
						`${config.public.AIRLOCK_SERVICE_URL}/enrollment/recent`,
						payload,
						{
							headers: { Authorization: `Bearer ${authToken.value}` },
						},
					);
				userEnrollment.value = recentEnrollment.data;
				const tokenCookie = useCookie("cbp-token", {
					path: "/",
					maxAge: 60 * 60 * 24 * 7,
				});
				tokenCookie.value = authToken.value;
			} catch (error) {
				console.log(error);
				datadogRum.addError(error, {
					message: "getEnrollmentInfo",
				});
			}
		}

		async function getUser(token: string) {
			const tokenCookie = useCookie("cbp-token", {
				path: "/",
				maxAge: 60 * 60 * 3,
			});
			tokenCookie.value = token;
			try {
				const fetchUser = await axios.get<User>(
					`${config.public.TENANT_SERVICE_URL}/user`,
					{
						headers: { Authorization: `Bearer ${token}` },
					},
				);
				if (!fetchUser.data?.emailVerified) {
					router.push({ path: "/email-not-verified" });
				}
				// We fetch for the tenant in case this was a federated login attempt (user signing into other business)
				const fetchTenant = await axios.get<Tenant>(
					`${config.public.TENANT_SERVICE_URL}/tenant`,
					{
						headers: { Authorization: `Bearer ${token}` },
					},
				);
				tenant.value = fetchTenant.data;
				user.value = fetchUser.data;
				await getCustomerInfo();
				await retrieveUserConsent();
				if (userConsent.value) {
					consentModal.value = false;
				} else {
					consentModal.value = true;
				}
			} catch (error) {
				if (isAxiosError(error)) {
					if (error.response?.status === 404) {
						console.info("Could not find user, defaulting to anonymous");
						datadogRum.addError(error, {
							message: "Could not find user, defaulting to anonymous",
						});
						return;
					}
				}
				console.error("Error finding user", error);
				datadogRum.addError(error, {
					message: "Error finding user",
				});
			}
		}

		function inactiveApplicationNetworkLoad(payload: ApplicationPolicy[]) {
			datadogRum.addAction("Post rule evaluation  of inactive applications", {
				inactiveApplications: payload,
			});
		}

		function inactivePoliciesNetworkLoad(payload: ApplicationPolicy[]) {
			datadogRum.addAction("Post rule evaluation  of inactive policies", {
				inactivePolicies: payload,
			});
		}

		async function isEmailVerified() {
			try {
				await axios.get(
					`${config.public.TENANT_SERVICE_URL}/user/email-confirmation/${userObject.value.userId}/${userObject.value.userOtp}`,
					{ headers: { Authorization: `Bearer ${authToken.value}` } },
				);
				isVerified.value = true;
				dupEmail.value = false;
				isLoading.value = false;
			} catch (error) {
				if (isAxiosError(error)) {
					if (error.response?.status == 404) {
						if (error.response?.data.message === "User could not be found") {
							console.log("User could not be found");
							alertMessage.value = "User is not found.";
							datadogRum.addError(error, {
								message: "User could not be found",
							});
							// router.push({ path: "/sign-in" });
							navigateTo({ path: "/sign-in" });
						} else {
							isVerified.value = false;
							isLoading.value = false;
							emailVerificationSubmitError.value = true;
						}
					} else if (error.response?.status == 400) {
						// Invalid OTP
						datadogRum.addError(error, {
							message: "Invalid OTP",
						});
						isVerified.value = false;
						isLoading.value = false;
						emailVerificationSubmitError.value = true;
					}
				}
			}
		}

		function preSaveUserInfo(userObject: PreSaveUserData) {
			datadogRum.setUser({
				name: userObject.firstName + " " + userObject.lastName,
				firstName: userObject.firstName,
				lastName: userObject.lastName,
				email: userObject.email,
				accountIds: "",
			});
		}

		async function register(data: SignUpUserPayload) {
			try {
				const payload = {
					...data,
					tenantId: tenant.value?.id,
				};
				const newUserId = await axios.post<string>(
					`${config.public.TENANT_SERVICE_URL}/login/v3/password/register`,
					payload,
				);
				userObject.value.userId = newUserId.data;
				userObject.value.userEmail = data.email;
				await axios.post(
					`${config.public.TENANT_SERVICE_URL}/user/v2/email-verification?origin=benefit`,
					{
						email: data.email,
						userId: userObject.value.userId,
					},
				);
				accountCreationInProgress.value = false;
				router.push({ path: "/email-verification" });
				codeExpiry.value = new Date().getTime() + 15 * 60 * 1000;
			} catch (error) {
				accountCreationInProgress.value = false;
				if (isAxiosError(error)) {
					if (
						error?.response?.status === 400 ||
						error?.response?.status === 404
					) {
						registrationNotFound.value = true;
						datadogRum.addError(error, {
							message: "Resgistration not found",
						});
					} else if (error?.response?.status === 409) {
						const responseMessage = JSON.parse(error.response.data.message);
						accountAlreadyExists.value = true;
						isVerified.value = responseMessage.isEmailVerified;
						userObject.value.userId = responseMessage.userId;
						userObject.value.userEmail = data.email;
						datadogRum.addError(responseMessage, {
							message: "Account already exits",
						});
					}
				}
			}
		}

		async function requestPasswordReset(data: PasswordResetPayload) {
			try {
				const payload = {
					...data,
					tenantId: tenant.value?.id,
				};
				await axios.post(
					`${config.public.TENANT_SERVICE_URL}/login/v2/password/forgot`,
					payload,
					{ headers: { Authorization: `Bearer ${authToken.value}` } },
				);
				// eslint-disable-next-line no-undef
				router.push({ path: "/reset-email-sent" });
			} catch (error) {
				router.push({ path: "/account-not-found" });
			}
		}

		async function resendVerificationEmail() {
			try {
				await axios.post(
					`${config.public.TENANT_SERVICE_URL}/user/v2/email-verification?origin=benefit`,
					{
						email: userObject.value.userEmail,
						userId: userObject.value.userId,
					},
					{ headers: { Authorization: `Bearer ${authToken.value}` } },
				);
				const expiry = new Date().getTime() + 15 * 60 * 1000;
				if (process.client) {
					localStorage.setItem("codeExpiry", expiry.toString());
				}
				codeExpiry.value = expiry;
				alertMessage.value = "";
				router.push({ path: "/email-verification" });
			} catch (error) {
				console.log("Error: ", error);
				datadogRum.addError(error, {
					message: "Error sending verification email",
				});
			}
		}

		async function signIn(data: SignInUserData) {
			try {
				const payload = {
					...data,
					tenantId: tenant.value?.id,
				};
				const token = await axios.post<string>(
					`${config.public.TENANT_SERVICE_URL}/login/password`,
					payload,
				);
				authToken.value = token.data;
				authValid.value = true;
				alertMessage.value = "";
				await getUser(authToken.value);
				if (config.public.POLICY_CONCIERGE_BETA_FLAG) {
					const publicyConciergeBetaUsers =
						config.public.POLICY_CONCIERGE_BETA_USERS.split("|");
					if (publicyConciergeBetaUsers.includes(user.value.id)) {
						return (showPolicyConcierge.value = true);
					} else {
						showPolicyConcierge.value = false;
					}
				}
				await router.push({ path: "/dashboard" });
			} catch (error) {
				if (isAxiosError(error)) {
					if (error?.response?.status == 404) {
						if (
							error?.response?.data.message ===
							"Unique email address could not be found"
						) {
							console.log("duplicate email found");
							dupEmail.value = true;
							isNameSubmitted.value = false;
						} else {
							dupEmail.value = false;
							isNameSubmitted.value = true;
							// This comment is set as a reminder to what this error is for.
							// It is an error when the dob, first name, or last name (for duplicate account) is wrong and is based off this code here --> commit("setAlertMessage", "Please check your inputs.");
							authValid.value = false;
							// Set name fields to empty
						}
						return;
					} else if (error?.response?.status == 401) {
						dupEmail.value = false;
						isNameSubmitted.value = true;
						// this comment is to help understand and remind us what this error is for.
						// It is an error when the password is wrong based off this code here --> commit("setAlertMessage", "Password" is incorrect, Please check your input.");
						authValid.value = false;
						alertMessage.value =
							"The email or password was incorrect. Please check your login information";
						return;
					} else if (error?.response?.status == 400) {
						const str = error?.response.data.message;
						const emailUnverified = str.split("::");
						userObject.value.userId = emailUnverified[1];
						if (emailUnverified[0] === "UNVERIFIED_EMAIL") {
							router.push({ path: "/email-not-verified" });
						}
						return;
					}
				}
				alertMessage.value = "Unexpected error occurred.";
			}
		}

		async function signOut() {
			dupEmail.value = false;
			authToken.value = "";
			customerInfo.value = {} as CustomerInfoDTO;
			user.value = {} as User;
			userEnrollment.value = {} as UserEnrollment;
			const tokenCookie = useCookie("cbp-token");
			tokenCookie.value = null;
		}

		async function retrieveUserConsent() {
			try {
				const res = await axios.get<CustomerConsentDTO>(
					`${config.public.AIRLOCK_SERVICE_URL}/portal/consent?firstName=${user.value.info.givenName}&lastName=${user.value.info.familyName}&birthDate=${user.value.info.dateOfBirth}&tenantId=${user.value.tenantId}&email=${user.value.info.email}`,
					{
						headers: {
							Authorization: `Bearer ${authToken.value}`,
						},
					},
				);
				userConsent.value = res.data;
			} catch (error) {
				console.log(error);
				userConsent.value = {} as CustomerConsentDTO;
			}
		}

		async function submitUserConsent(consent: boolean) {
			const payload = { transferConsent: consent };
			try {
				await axios.patch<CustomerConsentDTO>(
					`${config.public.AIRLOCK_SERVICE_URL}/portal/consent?tenantId=${user.value.tenantId}&firstName=${user.value.info.givenName}&lastName=${user.value.info.familyName}&birthDate=${user.value.info.dateOfBirth}&email=${user.value.info.email}`,
					payload,
					{
						headers: {
							Authorization: `Bearer ${authToken.value}`,
						},
					},
				);
				retrieveUserConsent();
			} catch (error) {
				console.log(error);
			}
		}

		function resetInfoModal() {
			showMissingInfoModal.value = false;
		}

		return {
			accountAlreadyExists,
			activeApplicationNetworkLoad,
			accountCreationInProgress,
			activePoliciesNetworkLoad,
			alertMessage,
			applications,
			assignPolicies,
			authToken,
			authValid,
			computedInboundPhone,
			customerInfo,
			userEnrollment,
			consentModal,
			showMissingInfoModal,
			resetInfoModal,
			codeExpiry,
			defaultTenant,
			displayProgressBar,
			dupEmail,
			emailVerificationSubmitError,
			fetchPolicies,
			fetchTenant,
			forgotPassword,
			getCustomerInfo,
			getEnrollmentInfo,
			getUser,
			inactiveApplicationNetworkLoad,
			inactivePoliciesNetworkLoad,
			isAnonymous,
			isAuthenticated,
			isEmailVerified,
			isLoading,
			isNameSubmitted,
			inboundPhone,
			isVerified,
			postEffectiveDateDays,
			policies,
			preSaveUserInfo,
			planStatus,
			register,
			registrationNotFound,
			requestPasswordReset,
			resendVerificationEmail,
			retrieveUserConsent,
			showPolicyConcierge,
			showMobileBanner,
			showProgressBar,
			signIn,
			signOut,
			submitUserConsent,
			tenant,
			user,
			userConsent,
			userObject,
		};
	},
	{
		persist: {
			storage: persistedState.localStorage,
		},
	},
);
