import { datadogRum } from "@datadog/browser-rum";

export const displayRules = [
	{
		ruleMode: "application",
		ruleStatus: "Submitted",
		uiDisplayStatus: "Submitted",
		statusColor: "#F7CD5E",
		active: "Yes",
		displayOnPolicyPage: "N/A",
		hideRule: "show",
	},
	{
		ruleMode: "application",
		ruleStatus: "UW Decline",
		uiDisplayStatus: "Underwriting Decline",
		statusColor: "#FF4D39",
		active: "No",
		displayOnPolicyPage: "N/A",
		hideRule: "show",
	},
	{
		ruleMode: "application",
		ruleStatus: "Withdrawn",
		uiDisplayStatus: "Withdrawn Application",
		statusColor: "#003459",
		active: "No",
		displayOnPolicyPage: "N/A",
		hideRule: "show",
	},
	{
		ruleMode: "application",
		ruleStatus: "Transferred",
		uiDisplayStatus: "Transferred",
		statusColor: "#F48B4A",
		active: "No",
		displayOnPolicyPage: "N/A",
		hideRule: "hide",
	},
	{
		ruleMode: "application",
		ruleStatus: "Pending",
		uiDisplayStatus: "Pending Submission",
		statusColor: "#F7CD5E",
		active: "Yes",
		displayOnPolicyPage: "N/A",
		hideRule: "hide",
	},
	{
		ruleMode: "application",
		ruleStatus: "Unknown",
		uiDisplayStatus: "Pending Decision",
		statusColor: "#F7CD5E",
		active: "No",
		displayOnPolicyPage: "N/A",
		hideRule: "hide",
	},
	{
		ruleMode: "application",
		ruleStatus: "Incomplete Submission",
		uiDisplayStatus: "Application Not Completed",
		statusColor: "#FF4D39",
		active: "Yes",
		displayOnPolicyPage: "N/A",
		hideRule: "hide",
	},
	{
		ruleMode: "application",
		ruleStatus: "Resubmitted",
		uiDisplayStatus: "Resubmitted",
		statusColor: "#F7CD5E",
		active: "Yes",
		displayOnPolicyPage: "N/A",
		hideRule: "hide",
	},
	{
		ruleMode: "application",
		ruleStatus: "Status Pending",
		uiDisplayStatus: "Status Pending",
		statusColor: "#F7CD5E",
		active: "Yes",
		displayOnPolicyPage: "N/A",
		hideRule: "hide",
	},
	{
		ruleMode: "application",
		ruleStatus: "Enrollment-Current Aetna MA Plan Change",
		uiDisplayStatus: "Current Aetna MA Plan Change",
		statusColor: "#003459",
		active: "Yes",
		displayOnPolicyPage: "N/A",
		hideRule: "hide",
	},
	{
		ruleMode: "application",
		ruleStatus: "Not Interested in Enrolling",
		uiDisplayStatus: "Not Interested",
		statusColor: "#003459",
		active: "No",
		displayOnPolicyPage: "N/A",
		hideRule: "hide",
	},
	{
		ruleMode: "policy",
		ruleStatus: "Terminated",
		uiDisplayStatus: "Terminated",
		statusColor: "#FF4D39",
		active: "No",
		displayOnPolicyPage: "always",
		hideRule: "show",
	},
	{
		ruleMode: "policy",
		ruleStatus: "Issued",
		uiDisplayStatus: "Issued",
		statusColor: "#158B55",
		active: "Yes",
		displayOnPolicyPage: "afterEffectiveDate",
		hideRule: "showAfterEffectiveDate",
	},
	{
		ruleMode: "policy",
		ruleStatus: "Withdrawn",
		uiDisplayStatus: "Terminated",
		statusColor: "#003459",
		active: "No",
		displayOnPolicyPage: "always",
		hideRule: "show",
	},
	{
		ruleMode: "policy",
		ruleStatus: "Submitted",
		uiDisplayStatus: "Submitted",
		statusColor: "#F7CD5E",
		active: "neverDisplayed",
		displayOnPolicyPage: "never",
		hideRule: "hide",
	},
	{
		ruleMode: "policy",
		ruleStatus: "UW Decline",
		uiDisplayStatus: "Underwriting Decline",
		statusColor: "#FF4D39",
		active: "neverDisplayed",
		displayOnPolicyPage: "never",
		hideRule: "hide",
	},
	{
		ruleMode: "policy",
		ruleStatus: "Unknown",
		uiDisplayStatus: "Unknown",
		statusColor: "#FF4D39",
		active: "neverDisplayed",
		displayOnPolicyPage: "never",
		hideRule: "hide",
	},
];

const surpressDisplayRules = [
	{
		product: "Critical Illness",
		carrierName: "Mutual of Omaha",
	},
	{
		product: "Affordable Care Act",
		recordType: "Individual Family Plan",
	},
];

const findRule = (ruleMode, status) => {
	const foundRule = displayRules.find((element) => {
		return element.ruleMode === ruleMode && element.ruleStatus === status;
	});
	// Set default for unmapped displayRule if status is not found in the displayRules map
	if (!foundRule) {
		if (ruleMode === "application") {
			return {
				ruleMode: "application",
				ruleStatus: status,
				uiDisplayStatus: "Unknown",
				statusColor: "#FF4D39",
				active: "No",
				displayOnPolicyPage: "never",
				hideRule: "hide",
				pageToDisplayOn: "",
			};
		} else if (ruleMode === "policy") {
			return {
				ruleMode: "policy",
				ruleStatus: status,
				uiDisplayStatus: "Unknown",
				statusColor: "#FF4D39",
				active: "neverDisplayed",
				displayOnPolicyPage: "never",
				hideRule: "hide",
				pageToDisplayOn: "",
			};
		}
	}
	return foundRule;
};
/**
     Both a policy and an application share a common policyNumber
     The page to display on (application or policy) is controlled by the policyStatus
      1. Look up the policy status for this application or policy by policyNumber
      2. Look up the policy rule that controls the page to be displayed on.
   */
const enrichWithRule = (elem, ruleMode, policies) => {
	console.log(`***1. enrichWithRule ${ruleMode}`, elem);
	// make a copy of displayRule since we want to modify the copy and append the modification to the response.
	const displayRule = { ...findRule(ruleMode, elem.status) };
	const elemEffectiveDate = elem.effectiveDate;
	let foundPolicy = null;
	let applicationPolicyNumberIsBlank = false;
	// Determine page to display the card on using the policy status rule
	// The rule looks up the policy of the application or policy by policyNumber.
	// The purpose of pageToDisplayOn is to only show a specific app or policy number only on one of the 2 pages.
	// setting pageToDisplayOn doesn't actually 'move' a card from one page to the other,
	// instead it will effectively hide the card if the pageToDisplayOn doesn't match the type.
	// if pageToDisplayOn === 'application, and this is a policy, the policy won't be shown on the policy page.
	if (ruleMode === "policy") {
		foundPolicy = elem; // use the current policy as the foundPolicy
	} else {
		if (elem.policyNumber === "") {
			console.log(`Policy number is blank for `, elem);
			applicationPolicyNumberIsBlank = true;
		}
	}
	if (foundPolicy === null) {
		// look up the application's matching policy by policy number
		// returns undefined if no matches are found
		console.log(`Looking up policy ${elem.policyNumber} for `, elem);
		foundPolicy = policies.find((policy) => {
			return policy.policyNumber === elem.policyNumber;
		});
		console.log(`Policy Result is ${elem.policyNumber}`, foundPolicy);
	}
	if (foundPolicy === undefined || applicationPolicyNumberIsBlank === true) {
		// The rule cannot be applied without a matching policy so set the rule to default. The card will show.
		console.log(
			`${ruleMode} - NOT FOUND: matching policy by policyNumber ${elem.policyNumber} for `,
			elem,
		);
		displayRule.pageToDisplayOn = ruleMode;
	} else {
		console.log(
			`${ruleMode} - FOUND: matching policy by policyNumber ${elem.policyNumber} for `,
			elem,
		);
		// Set the displayOnPolicyPage rule result into the pageToDisplayOn field value
		const policyStatus = foundPolicy.status;
		const policyRule = findRule("policy", policyStatus);
		console.log(
			"***2. enrichWithRule - policyRule is resolved to be:",
			policyRule,
		);
		if (policyRule.displayOnPolicyPage === "always") {
			displayRule.pageToDisplayOn = "policy";
		} else if (policyRule.displayOnPolicyPage === "never") {
			displayRule.pageToDisplayOn = "application";
		} else if (policyRule.displayOnPolicyPage === "afterEffectiveDate") {
			if (isTodayAfterThisDate(elemEffectiveDate)) {
				displayRule.pageToDisplayOn = "policy";
			} else {
				displayRule.pageToDisplayOn = "application";
			}
		} else {
			displayRule.pageToDisplayOn = "application";
		}
	}

	// Convert hideRule showAfterEffectiveDate to simple show rule
	if (
		displayRule.hideRule === "showAfterEffectiveDate" &&
		ruleMode === "policy" &&
		isTodayAfterThisDate(elemEffectiveDate)
	) {
		// replace complex hideMode rule 'showAfterEffectiveDate' with simple rule policyPage UI can easily use
		displayRule.hideRule = "show";
	}
	console.log(
		`***3. enrichWithRule ${ruleMode} - displayRule Result`,
		displayRule,
	);

	const checkProductAndCarrierName = surpressDisplayRules.filter(
		(rule) =>
			JSON.stringify(rule) ===
			JSON.stringify({ product: elem.product, carrierName: elem.carrierName }),
	);
	const checkProductAndRecordType = surpressDisplayRules.filter(
		(rule) =>
			JSON.stringify(rule) ===
			JSON.stringify({ product: elem.product, recordType: elem.recordType }),
	);
	console.log(
		"****Check product, carrierName, and recordType:",
		checkProductAndCarrierName,
		checkProductAndRecordType,
		elem,
	);
	if (
		checkProductAndCarrierName.length > 0 ||
		checkProductAndRecordType.length > 0
	) {
		displayRule.hideRule = "hide";
		console.log("****HIDDEN");
	}
	return {
		...elem,
		displayRule: displayRule,
	};
};

const isTodayAfterThisDate = (date) => {
	const today = new Date();
	const dateToCompare = new Date(date);
	datadogRum.addAction("Result of function isTodayAfterThisDate", {
		isTodayAfterThisDate: today > dateToCompare,
		today: today,
		dateToCompare: dateToCompare,
	});
	/**
	 * this will return true if today is later than dateToCompare
	 * sample 1 today (September 26, 2024) > dateToCompare (September 20, 2024) = true
	 * sample 2 today (September 26, 2024) > dateToCompare (September 30, 2024) = false
	 */

	/**
	 * Right now in current implementation, we only show the application/policy if the effective date is before today. 
	 * Or if we base on the condition above, application/policy will only show or display if this function return true.
	 */
	return today > dateToCompare;
	
};

export const resolvePolicyRules = (result) => {
	const applications = result.applications;
	const policies = result.policies;
	let applicationsWithRules;
	let policiesWithRules;
	// Assign & mix-in the displayRules mapped by status for each application or policy
	try {
		applicationsWithRules = applications.map((n) =>
			enrichWithRule(n, "application", policies),
		);
		datadogRum.addAction(
			"Result of function resolvePolicyRules - applicationsWithRules",
			{
				applicationsWithRules: applicationsWithRules,
			},
		);

		policiesWithRules = policies.map((n) =>
			enrichWithRule(n, "policy", policies),
		);
		datadogRum.addAction(
			"Result of function resolvePolicyRules - policiesWithRules",
			{
				policiesWithRules: policiesWithRules,
			},
		);
	} catch (e) {
		console.log("Unexpected error in resolvePolicyRules", e);
	}
	return {
		applications: applicationsWithRules,
		policies: policiesWithRules,
	};
};
